<template>
  <div class="financeInfo">
    <base-button label="+" @click="add"></base-button>
    <base-table
      :columns="financeInfocolumn"
      :tableAttrs="{
        data: financeInfoData,
        stripe: true,
      }"
      :isCaculateHeight="false"
      :showPage="false"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="yy">
        <!-- <el-date-picker
          v-model="xxx"
          type="year"
          value-format="timestamp"
          placeholder="请选择年份"
        >
        </el-date-picker> -->
        <font>xxxx</font>
      </template>
      <template slot="yy" slot-scope="scope">
        <el-input
          v-model="scope.row.yy"
        ></el-input>
      </template>
    </base-table>
  </div>
</template>
<script>
import baseTable from '@/components/common/table/base-table/base-table.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
export default {
  components: { baseTable, BaseButton },
  data () {
    return {
      financeInfocolumn: [
        { label: '序号', prop: 'code', width: '50' },
        { label: '科目（元）/周期（年）', prop: 'xx', width: '200' }
      ],
      financeInfoData: [
        { xx: '货币资金' },
        { xx: '应收账款' },
        { xx: '其他应收款' },
        { xx: '预付账款' },
        { xx: '总资产合计' },
        { xx: '总负债合计' },
        { xx: '主营业务收入' },
        { xx: '净利润' },
        { xx: '净利润率(%)' }
      ],
      allList: [
        { label: '货币资金', prop: 'monetaryFund' },
        { label: '应收账款', prop: 'accountsReceivable' },
        { label: '其他应收款', prop: 'OtherReceivables' },
        { label: '预付账款', prop: 'Prepayments' },
        { label: '总资产合计', prop: 'TotalAssets' },
        { label: '总负债合计', prop: 'TotalLiabilities' },
        { label: '主营业务收入', prop: 'MainBusinessIncome' },
        { label: '净利润', prop: 'NetProfit' },
        { label: '净利润率(%)', prop: 'NetProfitMargin' },
        { label: '资产负债率(%)', prop: 'AssetLiabilityRatio' },
        {
          label: '应收账款周转率(%)',
          prop: 'TurnoverRateOfAccountsReceivable'
        },
        { label: '现金短债比', prop: 'CashShortDebtRatio' }
      ]
    }
  },
  methods: {
    add () {
      this.financeInfocolumn.push({ prop: 'yy', width: '200' })
      console.log(this.financeInfoData)
      console.log(this.financeInfocolumn)
    }
  }
}
</script>
